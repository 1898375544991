<!--
 * @Author: Link
 * @Date: 2020-11-07 09:09:45
 * @LastEditTime: 2020-11-16 20:49:31
-->
<template>
  <orderDetail :type="type" />
</template>

<script>
import orderDetail from '@/component/orderDetail'
export default {
  components: { orderDetail },
  props: {
    type: String,
    default: 'refund'
  },
  data() {
    return {
      type: 'refund'
    }
  },
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped></style>
